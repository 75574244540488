<template>
  <div class="wrap-edit">
    <div class="edit-header">
      <span>慈溪市准予临时改变房屋用途决定书</span>
      <el-button type="primary" @click="print">打印</el-button>
    </div>
    <div class="edit-container">
      <div id="form" class="wrap wrap-zs1">
        <h1>慈溪市准予临时改变房屋用途决定书</h1>
        <p style="text-align:center">&emsp;{{ $store.state.AJH }}</p>
        <div class="wp-jds" style="margin-top:20px;">
          <span>宁波全程医疗产业园区管理有限公司：</span> <br />
          <span style="line-height: 2"
            >&emsp;&emsp;你(单位)
            向本部门提出临时改变房屋用途的申请，根据有关规定，经相关部门审核，同意临时改变房屋用途。具体许可内容如下（详见附图）：</span
          ><br />
        </div>
        <table width="100%" cellspacing="1" cellpadding="4" class="tabtop13" style="border: 1px solid #000000;">
          <tr>
            <td rowspan="1" style="width: 210px;">房屋坐落</td>
            <td colspan="5"></td>
          </tr>
          <tr>
            <td rowspan="1" >不动产权证号</td>
            <td colspan="5"></td>
          </tr>
          <tr>
            <td rowspan="1">临时改变用途建筑面积（㎡）</td>
            <td colspan="5"></td>
          </tr>
          <tr>
            <td rowspan="1">改变后用途</td>
            <td colspan="5"></td>
          </tr>
          <tr>
            <td rowspan="1" >有效期至</td>
            <td colspan="5"></td>
          </tr>
        </table>
        <div class="wp-zj" style="margin-top:20px;">
          <div class="wpz-wpl">注：</div>
          <div class="wpz-wpr">
            ①临时改变房屋用途有效期为五年，五年期限届满确需延期的，应当在期限届满前六十日内持房屋安全鉴定报告等资料向本部门申请办理延续手续，每次延续期限不得超过两年，未重新申请或申请未获得批准的，应当自行恢复原房屋用途；
            <br />
            ②临时改变用途的房屋同时应满足消防、环保、房屋结构安全及其它有关规范的要求；
            <br />
            ③拆迁时按房屋土地原用途进行补偿，已经缴纳的土地收益金不予退还；
            <br />
            ④土地收益金以一年一交方式收取，逾期不缴纳的，本决定书自动失效。
          </div>
        </div>
        <p class="right-align">慈溪市自然资源和规划局</p>
        <p class="right-align">
          {{ nowTime }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import print from "print-js";

export default {
  name:'fwytjds',
  data() {
    return {
      form: {},
      nowTime: ''
    };
  },
  methods: {
    getDate() {
      var a = new Date()
      var c = a.toLocaleDateString();
      var d = c.split("/");
      d[0] = d[0] + '年'
      d[1] = d[1] + '月'
      d[2] = d[2] + '日'
      this.nowTime = d.join('')
    },
    async fetch(Id) {
      try {
        await this.$ajax
          .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getlahz?Id=" + Id)
          .then((response) => {
            if (response.data.IsSuccess) {
              const data = response.data.Data;
              this.form.jjr = data.JJRXM;
            } else {
              throw response.data.ErrorMessage;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    print() {
      print({
        printable: "form",
        type: "html",
        scanStyles: false,
        css: "/static/print-style.css",
        documentTitle: "",
      });
    },

  },
  mounted() {
    this.getDate()
  },
  watch: {
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: 10px;
}
.edit-container {
  margin-top: 0px;
}

.right-align {
  text-align: right;
}

#form {
  padding: 20px;
  width: calc(100% - 42px);
  margin: auto;
  background-color: white;
  border: 1px black solid;
  overflow: hidden;
}

p {
  margin: 1em 0;
  text-align: center;
}

.body {
  text-indent: 2em;
}
strong {
  text-decoration: underline;
}
h1 {
  text-align: center;
  font-size: 2em;
}
.tabtop13 td {
  background-color: #ffffff;
  padding: 0 8px;
}
.wrap table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #000000;
}
.wrap table td {
  border: 1px solid #000000;
  height: 40px;
}

.wp-zj {
  position: relative;
  font-size: 12px;
}
.wpz-wpl {
  display: inline-block;
  position: absolute;
}
.wpz-wpr {
  display: inline-block;
  margin-left: 20px;
}
</style>
